function subscriptionRequestAdded(partialView)
{
    $(".news-subscription-result").html(partialView)
} // subscriptionRequestAdded


function subscriptionResult(partialView)
{

    $(".news-fields-subscription-result").css('display', 'none');
    $(".news-fields-subscription-result").html(partialView);
    $(".news-fields-subscription-result").slideDown()
        .delay(1000)
        .slideUp();
} // subscriptionRequestAdded

function selectAllFieldsFunc() {

    // Get the selectAll checkbox
    var checkBox = $("#SelectAllFields");
    //get fields checkboxes
    var checkBoxesList = $(".field-checkbox");

    if (checkBox.prop('checked') === true) {
        checkBoxesList.prop('checked', true);
    } else {
        checkBoxesList.prop('checked', false);
    }
}

//set selectAll to checked if all fields selected
function checkSelectAllWhenAllFieldsChecked() {
    var allChecked = true;

    // Get the selectAll checkbox
    var checkBox = $("#SelectAllFields");

    //get fields checkboxes
    var checkBoxesList = $(".field-checkbox");

    //check if any field is false
    checkBoxesList.each(function (index) {
        if ($(this).prop('checked') === false) {
            allChecked = false;
        }
    });

    if (allChecked === true) {
        checkBox.prop('checked', true);
    } else {
        checkBox.prop('checked', false);
    }
}//checkSelectAllWhenAllFieldsChecked
