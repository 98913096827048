document.addEventListener("DOMContentLoaded", function () {

    // elements
    var min = 1000; /* 10s */
    var max = 3000; /* 25s */
    var daysToShow = 7;
    var autoShowPopup = true;

    var popupButton = document.getElementById("statsi-popup-button");
    var cancelButton = document.getElementById("statsi-popup-cancel-button");
    var solveButton = document.getElementById("statsi-popup-confirm-button");
    var modal = document.getElementById("statsi-popup-modal");
    var modalContent = document.getElementById("statsi-popup-modal-content");
    var close = document.getElementById("statsi-popup-close-modal");               

    // check if browser supports local storage
    if (typeof (Storage) !== "undefined") {

        var storageData = localStorage.getItem("SURS_Survey_popup");
        var surveySolved = localStorage.getItem("SURS_Survey_solved");

        if (storageData == null) {
            SetPopupTimmer(autoShowPopup);
        }
        else {
            // razlika v dnevih v datumih
            var diff = Math.abs((new Date() - new Date(storageData))/1000/60/60/24); 
            if (diff >= daysToShow && surveySolved != "true") {
                // ce je dan razlicen od danes prikazemo popup
                SetPopupTimmer(autoShowPopup);
            }
        }
        /*localStorage.setItem("SURS_Survey_popup", )*/
    }

    function SaveToLocalStorage(item, date) {
        localStorage.setItem(item, date);
    }

    function SetPopupTimmer(showPopup) {

        if (showPopup == true) {
            setTimeout(function () {
                ShowModal(modal, modalContent);
                SaveToLocalStorage("SURS_Survey_popup", (new Date()).toLocaleDateString());
                SaveToLocalStorage("SURS_Survey_solved", false);
            }, RandomTimmer(min, max))
        }
    }

    // if no local storage only active button

    /* events */
    popupButton.addEventListener("click", function () {
        ShowModal(modal, modalContent);
        SaveToLocalStorage("SURS_Survey_popup", (new Date()).toLocaleDateString());
    });

    cancelButton.addEventListener("click", function () {
        HideModal(modal, modalContent);
    });

    solveButton.addEventListener("click", function () {
        SaveToLocalStorage("SURS_Survey_solved", true);
        console.log("solve");
    });

    close.addEventListener("click", function () {

        HideModal(modal, modalContent);
    });

    close.addEventListener("keydown", function (event) {
        // enter or space to close modal
        if (event.keyCode == "13" || event.keyCode == "32") {
            HideModal(modal, modalContent);
        }
    });

    window.onclick = function (event) {
        if (event.target == modal) {
            HideModal(modal, modalContent);
        }
    }

    function HideModal(modal, modalContent) {
        modalContent.classList.remove("show");
        modalContent.classList.add("hide");
        modal.classList.add("fadeOut");
        setTimeout(function () {
            modalContent.classList.remove("hide");
            modal.classList.remove("fadeOut");
            modal.style.display = "none";
        }, 300);
        //popupButton.
    }

    function ShowModal(modal, modalContent) {
        modalContent.classList.remove("hide");
        modalContent.classList.add("show");
        modal.style.display = "block";
    }

    /* random time */
    function RandomTimmer(min, max) {
        return Math.floor(Math.random() * (max - min) + min);
    }

});