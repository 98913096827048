var currentLang;

function setCurrentLang(lang) {
    currentLang = lang;
}

function getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}


$(document).ready(function () {


    //COOKIES

    if (getCookie("surs_analitics_cookie") == "") {
        $(".surs-cookies-wrapper").show();
        //skrijemo tudi meni na strani za prikaz Dogodka 
        var interval = setInterval(function () {
            var counter = counter + 50;
            var dogodkiMenu = document.getElementsByClassName("menu-dogodki")[0];
            // maksimalno 500ms se pregleduje ali je element na strani
            if (counter == 500) {
                clearInterval(interval);
            }
            if (dogodkiMenu != null){
                dogodkiMenu.style.display = "none";
                clearInterval(interval);
            }
        }, 50);

    } else {
        $(".surs-cookies-wrapper").hide();//Dodano za GDPR
        //prikazemo meni
        var dogodkiMenu = document.getElementsByClassName("menu-dogodki")[0];
        if (dogodkiMenu != null) {
            dogodkiMenu.style.display = "block";
        }
    }

    //COOKIE od JERASA 
    /*$(".btn-cookies").click(function () {

        //$.cookie('analitics_cookie', 1);
        var d = new Date();
        d.setTime(d.getTime() + (30 * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = "surs_analitics_cookie=1;" + expires;
        $(".surs-cookies-wrapper").hide();

    });*/

    var wrapper = $('.surs-cookies-wrapper');

    if (wrapper.length) {
        var focusableEls = wrapper.find('a, button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])');
        var firstFocusableEl = focusableEls.first();
        var lastFocusableEl = focusableEls.last();

        // Focus on the link "Več o piškotkih"
        var link = wrapper.find('a').first();
        if (link.length) {
            link.focus();
        }

        wrapper.on('keydown', function (e) {
            var isTabPressed = (e.key === 'Tab' || e.keyCode === 9);

            if (!isTabPressed) {
                return;
            }

            if (e.shiftKey) { // if shift key pressed for shift + tab combination
                if ($(document.activeElement).is(firstFocusableEl)) {
                    lastFocusableEl.focus();  // Move focus to the last element if Shift+Tab is pressed at the first element
                    e.preventDefault();
                }
            } else { // if tab key is pressed
                if ($(document.activeElement).is(lastFocusableEl)) {
                    firstFocusableEl.focus();  // Move focus to the first element if Tab is pressed at the last element
                    e.preventDefault();
                }
            }
        });
    }


    //TRIGGER BETA POPUP

    if (localStorage.getItem('betasursVisited') == undefined) {
         $('#popup-beta').modal();
         localStorage.setItem('betasursVisited', 1);
    }
   

    /*HEADER DRŽAVNE USTANOVE*/


        var getHeaderHeight = function () {
            var h = $('#header-ustanove').height();
            return h;
        }

        $('#navbar').affix({
            offset: {
                top: getHeaderHeight
            }
        });

   

    $('#navbar').on('affix.bs.affix', function () {
        $('body').css('margin-top', '63px');

    });

    $('#navbar').on('affix-top.bs.affix', function () {
        $('body').css('margin-top', 0);
    });

    /*MENU*/

    $('#navbar .dropdown').on('show.bs.dropdown', function () {
        $(this).find('.surs-openmenu-narrow').removeClass('surs-hidden');
        $('.surs-upper-line').removeClass('surs-hidden');
    })

    $('#navbar .dropdown').on('hide.bs.dropdown', function () {
        $(this).find('.surs-openmenu-narrow').addClass('surs-hidden');
        $('.surs-upper-line').addClass('surs-hidden');
    })

    function CloseNav(navToClose, toToggle) {
        $(navToClose).slideUp("slow", function () {
            $(this).css({ 'height': '1px' }).css({ 'display': '' }).removeClass('in').addClass("collapse");
        });
    }

    $('.surs-toggle-menu').click(function (event) {
        event.preventDefault();
        var clickover = $(event.target);
        var _opened = $("#navbar-collapse-grid-search").hasClass("collapse in");
        if (_opened === true && !clickover.hasClass("navbar-toggle")) {
            CloseNav("#navbar-collapse-grid-search", ".surs-toggle-search");
        }

    });

    $('.surs-toggle-search').click(function (event) {
        event.preventDefault();
        var clickover = $(event.target);
        var _opened = $("#navbar-collapse-grid-menu").hasClass("collapse in");
        if (_opened === true && !clickover.hasClass("navbar-toggle")) {
            CloseNav("#navbar-collapse-grid-menu", ".surs-toggle-menu");
        }

    });

    $('.dropdown.yamm-fw').click(function () {
    
        var openMenuHeight= $(this).prevAll().find($('.dropdown.yamm-fw.open ul')).height();
        var firstItem = $('li[data-menu-order="first"]').offset().top;
        var currentItem = $(this).offset().top;
        var offset = currentItem - firstItem - openMenuHeight;

        //alert("firstItem Offset: " + firstItem + " currentItem offset: " + currentItem + " openmenu height: " + openMenuHeight + " offset: " + offset);

        $('.navbar-collapse').animate({
            scrollTop: offset
        },200);
    });

    setMenuHeight();
    $(window).resize(function () {
        setMenuHeight();
    });
    
    $(".surs-ontop").click(function () {
        $('html, body').animate({ scrollTop: 0 }, 'slow');
        $('#surs-home').focus();
    });
    

    $(".surs-ontop").keypress(function (event) {
        if (event.which == 13 || event.which == 32) {
            $('html, body').animate({ scrollTop: 0 }, 'slow');
            $('#surs-home').focus();
        }
    });


    setOntopButtonPosition();
    $(window).resize(function () {
        setOntopButtonPosition();
    });

    ////GOOGLE SEARCH

    //GOOGLE SEARCH onemogočimo, če ni strinjanja z uporabo cookijev

    var google_search_cookie_value = getCookie("google_agreement");

    if (google_search_cookie_value == "1") {
        //nalozimo search

        window.__gcse = {
            callback: myCallback
        };

        (function () {
            var cx = '000398524785082785064:d78lu3js_lg';
            var gcse = document.createElement('script');
            gcse.type = 'text/javascript';
            gcse.async = true;
            gcse.src = 'https://cse.google.com/cse.js?cx=' + cx;
            var s = document.getElementsByTagName('script')[0];
            s.parentNode.insertBefore(gcse, s);
        })();

    }//if

    setEvents();

    if ($("#unsubscribeTrigger") != undefined)
    {
        $("#unsubscribeTrigger").trigger("click");
        //$("#newsUnsubscribeModal").addClass("in");
    }//show unsubscribe from news modal 


    /* preskok glavne navigacije */
    $("#skip-menu-link").on("click", function () {
        GoToFirstFocusableElement("main-content", true);
    });

    $("#skip-menu-link").keypress(function (event) {
        // enter ali space
        if (event.which === 13 || event.which === 32)
        {
            event.preventDefault();
            GoToFirstFocusableElement("main-content", true);      
        }
    });

    /* preskok twitter vsebine */
    $("#skip-twitter-link").on("click", function () {
        GoToFirstFocusableElement("last-news", false);
    });

    $("#skip-twitter-link").keypress(function (event) {
        // enter ali space
        if (event.which === 13 || event.which === 32) {
            event.preventDefault();
            GoToFirstFocusableElement("last-news", false);
        }
    });

});

function GoToFirstFocusableElement(elementId, preventScroll)
{
    var focusable = document.getElementById(elementId).querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
    focusable[0].focus({ preventScroll: preventScroll });
}


function setOntopButtonPosition() {
    var w = $(window).width();
    if (w > 1250) {
        var nw = ((w - 1170) / 2) - 35;
        $(".surs-ontop").css("right", nw + "px");
    } else {
        $(".surs-ontop").css("right", "10px");
    }
}

function setEvents() {
    if ($('.gstl_50.gssb_c').length != 0) {
        $(".gstl_50.gssb_c").click(function (e) {     //function_td
            e.stopPropagation();
        });
    } else {
        setTimeout(setEvents, 500);
    }
}

function setMenuHeight() {
    $('.navbar-collapse').css('max-height', $(window).height() - $('.navbar-header').height());
}

/*YAMM PREVENT CLOSING ON CLICK*/

$(document).on('click', '.yamm .dropdown-menu', function (e) {
    e.stopPropagation();
})



function dataInquiryPosted(partialView)
{
    $(".contact-dinquiry-result").css('display', 'none');
    $(".contact-dinquiry-result").html(partialView);
    $(".contact-dinquiry-result").slideDown();//removed sliding up with delay
        /*.delay(2000)
        .slideUp();*/
    //$(".news-subscription-input").val("");//delete inputs
    $(".contact-agree").removeAttr('checked');
}
function feedbackFormPosted(partialView) {
    $(".feedback-form-result").css('display', 'none');
    $(".feedback-form-result").html(partialView);
    $(".feedback-form-result").slideDown()
        .delay(2000)
        .slideUp();
}

function myCallback() {
    if (currentLang == "sl") {
        $("td.gsc-search-button input").attr("value", "ISKANJE");
        $("td.gsc-search-button input").attr("title", "Išči");
    } else if(currentLang == "en") {
        $("td.gsc-search-button input").attr("value", "SEARCH");
        $("td.gsc-search-button input").attr("title", "Search");
    }
}




