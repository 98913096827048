function displayMap() {
    var gmarkers = [];
    var map;

    function initialize() {
        var latLng = new google.maps.LatLng(46.081509, 14.497144);
        var mapProp = {
            center: latLng,
            zoom: 17,
            mapTypeId: google.maps.MapTypeId.ROADMAP
        };

        map = new google.maps.Map(document.getElementById("googleMap"), mapProp);

        var marker = new google.maps.Marker({
            position: latLng,
            map: map,
            title: "Surs"
        });
    }

    google.maps.event.addDomListener(window, 'load', initialize);

    google.maps.event.addDomListener(window, 'resize', function () {
        var center = map.getCenter();
        google.maps.event.trigger(map, 'resize');
        map.setCenter(center);
    });
}